<template>
  <div class="unit-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="content" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 表单 -->
        <ml-form
          ref="unitActionRef"
          labelWidth="200px"
          style="width: 1200px; margin: 40px auto"
          :model="unitForm"
          :rules="unitFormRules"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">阿米巴信息</el-divider>
          <!-- 输入框-单位名称 -->
          <el-row>
            <el-col :span="8">
              <ml-input
                prop="companyName"
                label="阿米巴名称:"
                placeholder="请输入阿米巴名称"
                v-model.trim="unitForm.companyName"
              />
            </el-col>
            <!-- <el-col :span="8">
            <ml-input
              prop="companyCode"
              label="阿米巴编号:"
              placeholder="请输入阿米巴编号"
              v-model.trim="unitForm.companyCode"
            />
          </el-col> -->
            <el-col :span="16">
              <ml-input
                prop="companyUser"
                label="阿米巴负责人:"
                placeholder="请输入阿米巴负责人"
                v-model.trim="unitForm.companyUser"
              />
            </el-col>
          </el-row>

          <!-- 多级下拉框-单位地址 -->
          <el-row>
            <el-col :span="8">
              <ml-cascader
                required
                prop="areaArr"
                :props="{ label: 'name', value: 'name' }"
                label="阿米巴地址:"
                :options="areaData"
                placeholder="请选择阿米巴地址"
                v-model="unitForm.areaArr"
                style="width: 100%"
              />
            </el-col>
            <el-col :span="16">
              <!-- 输入框-详情地址 -->
              <ml-input
                prop="address"
                label="详细地址:"
                placeholder="请输入单位详细地址"
                v-model.trim="unitForm.address"
              />
            </el-col>
          </el-row>

          <!-- 输入框-详情地址 -->
          <ml-input
            prop="serviceDesc"
            label="阿米巴服务描述:"
            type="textarea"
            :rows="5"
            placeholder="请输入阿米巴服务描述"
            v-model.trim="unitForm.serviceDesc"
          />
          <!-- 输入框-气象服务 -->
          <ml-input
            prop="weatherUrl"
            label="web气象服务:"
            placeholder="请输入web气象服务"
            v-model.trim="unitForm.weatherUrl"
          />
          <!-- 输入框-气象服务 -->
          <ml-input
            prop="appWeatherUrl"
            label="小程序气象服务:"
            placeholder="请输入小程序气象服务"
            v-model.trim="unitForm.appWeatherUrl"
          />

          <!-- 树 -管理区域 -->
          <ml-tree
            prop="regionArray"
            label="管理区域:"
            :data="[{ code: 0, name: '全部', children: areaData }]"
            placeholder="请选择管理区域"
            v-model="unitForm.regionArray"
            v-model:selectData="unitForm.regions"
            treeClass="ml-tree"
            height="300px"
            node-key="code"
            :props="{ label: 'name' }"
          />
          <!-- 提交按钮 -->
          <ml-button
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            @click-submit="clickSubmitUnit"
            @click-cancel="clickCancelUnit"
          />
        </ml-form>
      </el-scrollbar>
    </ml-tip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { searchParams, areaArrTOObj, tabberHeight } from '../../utils'

export default {
  name: 'UnitAction',
  setup() {
    const unitActionRef = ref()
    const { commit, getters, dispatch } = useStore()
    const route = useRoute()
    const router = useRouter()
    // 获取区域
    const areaData = computed(() => getters.getArea)
    // 获取路径参数
    const { id } = route.query
    // 设置导航标题文本
    const content = ref(id ? '修改单位' : '添加单位')
    /* 开始 用户表单 **********************************/
    // 表单配置
    const unitForm = reactive({
      companyName: '',
      // companyCode: '',
      companyUser: '',
      areaArr: [],
      address: '',
      serviceDesc: '',
      weatherUrl: 'https://www.gd121.cn/tq/ld/list.shtml ',
      appWeatherUrl: 'https://wxc.gd121.cn/html/qxfw/typhoon/typhoon2/dist/#/main',
      regionArray: [],
      regions: []
    })

    // 新增修改校验
    const unitFormRules = {
      companyName: [{ required: true, message: '请输入阿米巴名称', trigger: 'blur' }],
      // companyCode: [{ required: true, message: '请输入阿米巴编号', trigger: 'blur' }],
      companyUser: [{ required: true, message: '请输入阿米巴负责人', trigger: 'blur' }],
      areaArr: [{ required: true, message: '请选择区域', trigger: 'blur' }],
      address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
      serviceDesc: [{ required: true, message: '请输入阿米巴服务描述', trigger: 'blur' }],
      regionArray: [{ required: true, message: '请选择管辖区域', trigger: 'blur' }]
    }
    // 提交表单
    const clickSubmitUnit = () => {
      unitActionRef.value.CustomFormRef.validate()
        .then(() => {
          let unitParams = searchParams(unitForm, ['areaArr', 'regions'])
          if (unitForm.areaArr.length > 0) {
            const areaObj = areaArrTOObj(unitForm.areaArr)
            unitParams = { ...unitParams, ...areaObj }
          }
          if (unitForm.regions.length > 0) {
            if (unitForm.regions[0].name === '全部') {
              unitParams.regions = unitForm.regions[0].children || []
            } else {
              unitParams.regions = unitForm.regions[0]
            }
          }
          if (content.value === '添加单位') {
            dispatch('fetchAddCompanyInfo', unitParams).then(data => {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              router.push({ path: '/unitManage' })
            })
          } else {
            unitParams.companyId = id
            dispatch('fetchUpdateCompanyInfo', unitParams).then(data => {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              router.push({ path: '/unitManage' })
            })
          }
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelUnit = () => {
      router.push({ path: '/unitManage' })
    }
    /* 结束 用户表单 **********************************/
    onMounted(() => {
      if (content.value === '修改单位') {
        dispatch('fetchGetCompanyInfo', id).then(data => {
          if (data && data.code === 200) {
            Object.keys(unitForm).map(item => {
              if (['areaArr'].includes(item)) {
                unitForm[item] = data.data.province
                  ? [data.data.province, data.data.city, data.data.area]
                  : [data.data.city, data.data.area]
              } else {
                if (
                  item === 'regions' &&
                  data.data[item].length > 0 &&
                  data.data[item][0].name != '全部'
                ) {
                  data.data[item] = [{ code: '0', name: '全部', children: data.data[item] }]
                }
                unitForm[item] = data.data[item]
              }
            })
          }
        })
      }
    })
    return {
      unitActionRef,
      areaData,
      content,
      unitForm,
      unitFormRules,
      clickSubmitUnit,
      clickCancelUnit,
      tabberHeight
    }
  }
}
</script>

<style lang="scss">
.unit-action {
  position: relative;
  .ml-tree {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 10px 15px;
    .el-tree {
      > .el-tree-node {
        > .el-tree-node__children {
          > .el-tree-node {
            > .el-tree-node__children {
              > .el-tree-node {
                > .el-tree-node__children {
                  white-space: break-spaces;
                  > .el-tree-node {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
